import React from "react";

const marginBottomStyle = { marginBottom: '25px' }
const pStyle = { fontSize: '16pt', lineHeight: '25px', marginBottom: '25px' }
const textColorStyle = { color: 'yellow' }
const ContentSection1 = () => {
    return (
        <>
            <div className="container-fluid text-center">
                <div className="row">
                    <div className="col">
                        <h1 style={marginBottomStyle} className="title-style">RTP Leaks Updated Daily Ensure That Playing Slots Guarantees A Better Chance Of Winning.</h1>
                        <p style={pStyle}>This site has provided a table of the Highest Win Rate Slot Games making it easier for you to choose which Slot Games to play. Play with confidence and bet responsibly.</p>
                        <h1 style={marginBottomStyle}><span style={textColorStyle}>BEST LIVE RTP SLOTS</span></h1>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContentSection1;
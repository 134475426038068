import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/custom.css'
import './App.css';
import GameTabSection from "./components/GameTabSection";
import NavSection from "./components/NavSection";
import ContentSection1 from "./components/ContentSection1";
import ContentSection2 from "./components/ContentSection2";
import FooterSection from "./components/FooterSection";

function App() {

  const colorBG = {
    backgroundColor: '#0b2133',
    borderColor: '#0b2133'
  }

  return (
    <div className="container-fluid" style={colorBG}>
      <NavSection />
      <ContentSection1 />
      <GameTabSection />
      <ContentSection2 />
      <FooterSection />
    </div>
  );
}

export default App;

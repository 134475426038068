import React, { useState, useEffect } from "react";
import Carousel from 'react-bootstrap/Carousel';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faSortAlphaAsc, faSortAlphaDesc, faSortAmountAsc, faSortAmountDesc } from '@fortawesome/free-solid-svg-icons';
import myData from '../data/games.json';

import pragmaticplay from '../images/pragmaticplay.png';
import playtech from '../images/playtech.png';
import habanero from '../images/habanero.png';
import spadegaming from '../images/spadegaming.png';
import playngo from '../images/playngo.png';
import nextspin from '../images/nextspin.png';

import joker from '../images/joker.png';
import redtiger from '../images/redtiger.png';
import cq9 from '../images/cq9.png';
import ebet from '../images/eBet.png';
import dragoonsoft from '../images/dragoonsoft.png';
import gameplay from '../images/gameplay.png';

import simpleplay from '../images/simpleplay.png';
import jdb from '../images/jdb.png';
import fachai from '../images/fachai.png';
import kingmaker from '../images/kingmaker.png';
import jili from '../images/jili.png';
import xe88 from '../images/xe88.png';


const GameTabSection = () => {

    const cardBodyStyle = {
        padding: '1px'
    }

    const carouselBgStyle = {
        backgroundColor: 'black'
    }

    const progressBarPadding = {
        height: '30px',
        padding: '-53px',
        borderRadius: '0px 5px'
    }

    const fontSizeRTP = {
        fontSize: '16pt'
    }


    const [games, setgames] = useState([]);
    const [sortRTPAscending, setsortRTPAscending] = useState(false);
    const [sortNameAscending, setsortNameAscending] = useState(true);
    const [carouselData, setcarouselData] = useState([]);
    const [carouselData1, setcarouselData1] = useState([]);
    const [carouselData2, setcarouselData2] = useState([]);
    const [gameTab, setgameTab] = useState(1);
    const [currentDate, setCurrentDate] = useState('');
    const [activeSortRTPButton, setactiveSortRTPButton] = useState(false);
    const [activeSortNameButton, setactiveSortNameButton] = useState(false);

    useEffect(() => {

        // Load the json data and filter it by game tab...
        const filteredData = myData.filter(item => item.type === gameTab);

        // Sort the json data by rtp descending
        setgames([...filteredData].sort((a, b) =>
            b.rtp - a.rtp
        ));

        setactiveSortRTPButton(true);
        setactiveSortNameButton(false);

        if (sortRTPAscending) {
            setsortRTPAscending(!sortRTPAscending);
        } else {
            setsortRTPAscending(sortRTPAscending);
        }

        // Load the carousel 1
        setcarouselData([
            { id: 1, title: 'pragmatic play', src: pragmaticplay },
            { id: 2, title: 'playtech', src: playtech },
            { id: 3, title: 'habanero', src: habanero },
            { id: 4, title: 'spadegaming', src: spadegaming },
            { id: 5, title: 'playngo', src: playngo },
            { id: 6, title: 'nextspin', src: nextspin },
        ]);

        // Load the carousel 2
        setcarouselData1([
            { id: 7, title: 'joker', src: joker },
            { id: 8, title: 'red tiger', src: redtiger },
            { id: 9, title: 'cq9', src: cq9 },
            { id: 10, title: 'ebet', src: ebet },
            { id: 11, title: 'dragoonsoft', src: dragoonsoft },
            { id: 12, title: 'gameplay', src: gameplay },
        ])

        // Load the carousel 3
        setcarouselData2([
            { id: 13, title: 'simpleplay', src: simpleplay },
            { id: 14, title: 'jdb', src: jdb },
            { id: 15, title: 'fachai', src: fachai },
            { id: 16, title: 'kingmaker', src: kingmaker },
            { id: 17, title: 'jili', src: jili },
            { id: 18, title: 'xe88', src: xe88 },
        ])

        getCurrentDate();

    }, [gameTab]);

    // Sort by RTP
    const toogleSortRTP = () => {
        setactiveSortRTPButton(true);
        setactiveSortNameButton(false);
        setsortRTPAscending(!sortRTPAscending);
        setgames([...games].sort((a, b) => {
            if (sortRTPAscending) {
                return b.rtp - a.rtp
            } else {
                return a.rtp - b.rtp
            }
        }));
    };

    // Sort by name
    const toogleSortName = () => {
        setactiveSortRTPButton(false);
        setactiveSortNameButton(true);
        setsortNameAscending(!sortNameAscending);
        setgames([...games].sort((a, b) => {
            if (sortNameAscending) {
                return a.name.localeCompare(b.name);
            } else {
                return b.name.localeCompare(a.name);
            }
        }));
    };

    // Game tab onclick function
    const selectGameTab = (id) => {
        setgameTab(id);
    };

    // Get the current date
    const getCurrentDate = () => {
        const date = new Date();
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        setCurrentDate(formattedDate);
    }

    return (

        <>
            <div className="row sticky-top justify-content-center">
                <Carousel style={carouselBgStyle} interval={null}>
                    <Carousel.Item >
                        <div className="text-center">
                            {carouselData.map((item, index) => (
                                <img src={item.src} className="m-2 carouselImageStyle" alt={"image " + item.title} onClick={() => selectGameTab(item.id)} key={index} />
                            ))}
                        </div>
                    </Carousel.Item>
                    <Carousel.Item >
                        <div className="text-center">
                            {carouselData1.map((item, index) => (
                                <img src={item.src} className="m-2 carouselImageStyle" alt={"image " + item.title} onClick={() => selectGameTab(item.id)} key={index} />
                            ))}
                        </div>
                    </Carousel.Item>
                    <Carousel.Item >
                        <div className="text-center">
                            {carouselData2.map((item, index) => (
                                <img src={item.src} className="m-2 carouselImageStyle" alt={"image " + item.title} onClick={() => selectGameTab(item.id)} key={index} />
                            ))}
                        </div>
                    </Carousel.Item>
                </Carousel>
            </div>

            <div className="row">
                <div className="col-md-6 col-sm-12 p-4 custom-padding">
                    <p className="today-rtp-info">
                        <strong>Today's RTP Slot Information</strong><br></br>
                        <span className="fst-italic text-muted">Updated as of {currentDate}</span>
                    </p>
                </div>
                <div className="col-md-6 col-sm-12 p-4 text-end custom-padding">
                    <strong className="hide-sort-label">Sort: </strong>
                    {/* <button
                        className={activeSortNameButton ? "btn btn-primary" : "btn btn-light"} style={{ marginRight: '5px' }}
                        onClick={toogleSortName}
                    >
                        {sortNameAscending ? "Alphabetical A-Z " : "Alphabetical Z-A "} {sortNameAscending ? <FontAwesomeIcon icon={faSortAlphaAsc} /> : <FontAwesomeIcon icon={faSortAlphaDesc} />}
                    </button> */}
                    <button
                        className={activeSortRTPButton ? "btn btn-primary" : "btn btn-light"}
                        onClick={toogleSortRTP}>
                        {sortRTPAscending ? "DESCENDING" : "ASCENDING"} RTP {sortRTPAscending ? <FontAwesomeIcon icon={faSortAmountAsc} /> : <FontAwesomeIcon icon={faSortAmountDesc} />}
                    </button>
                </div>
            </div>

            <div className="row justify-content-center">
                {games.map((game, index) => (
                    <div className="card cardStyle" key={index}>
                        <a href="https://facket-openting.com/click" target="_blank" className="cta">
                            <img src={game.src} className="card-img-top" alt={"image " + game.name} />
                        </a>
                        <div className="card-body" style={cardBodyStyle}>
                            <div className="progress" style={progressBarPadding}>
                                <div className={game.rtp >= 70 ? "progress-bar  bg-success" : game.rtp >= 50 && game.rtp < 70 ? "progress-bar  bg-warning" : "progress-bar  bg-danger"} role="progressbar" style={{ width: game.rtp + '%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                    <strong style={fontSizeRTP}>{game.rtp}%</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export default GameTabSection;
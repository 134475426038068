import React from "react";
import logo from '../images/FlashRTPlogo.gif';
const GameTabSection = () => {

    const aLinkColor = {
        color: 'white',
        fontSize: '15pt'
    }

    const activeNav = {
        color: 'white',
        fontSize: '15pt',
        backgroundColor: 'orange',
        borderRadius: '10px',
        padding: '5px'
    }

    const togglerIconBgColor = { backgroundColor: 'white' }

    return (

        <>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src={logo} alt="Logo" className="d-inline-block logo-size" />
                    </a>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" style={togglerIconBgColor}></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <ul className="navbar-nav" >
                            <li className="nav-item" >
                                <a className="nav-link text-uppercase" aria-current="page" href="#" style={aLinkColor}>Home</a>
                            </li>
                            <li className="nav-item" >
                                <a className="nav-link text-uppercase" href="#" style={aLinkColor}>Login</a>
                            </li>
                            <li className="nav-item" >
                                <a className="nav-link text-uppercase" href="#" style={aLinkColor}>Register</a>
                            </li>
                            <li className="nav-item" >
                                <a className="nav-link text-uppercase" href="#"><span style={activeNav}>RTP Slots</span></a>
                            </li>
                            <li className="nav-item" >
                                <a className="nav-link text-uppercase" href="#" style={aLinkColor}>Contact</a>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default GameTabSection;
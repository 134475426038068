import React, { useState, useEffect } from "react";

const marginBottomStyle = { marginBottom: '25px' }

const FooterSection = () => {
    const [currentDate, setCurrentDate] = useState('');
    useEffect(() => {
        getCurrentDate();
    }, []);
    // Get the current date
    const getCurrentDate = () => {
        const date = new Date();
        const options = { year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        setCurrentDate(formattedDate);
    }

    return (
        <>
            <div className="container-fluid text-center">
                <div className="row">
                    <div className="col">
                        <h5 style={marginBottomStyle}>© {currentDate} All Rights Reserved. 18+</h5>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FooterSection;